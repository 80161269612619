import React from 'react'

export default function BookPromo({ image }) {
  return (
    <a target="_blank" href="https://fullstacktao.com">
      <div className="book">
        <img style={{ borderRadius: 3, border: '1px solid #ccc', padding: 5, backgroundColor: '#fff' }} src={image} />
        <div>
          <h2 style={{ marginTop: 10, fontWeight: 400 }}>
            Full Stack Tao
          </h2>
          <div className="divider"></div>
          <p>
            Everything I've learned about building software in the last 10 years.
          </p>
        </div>
      </div>
    </a>
  )
}
